import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/richtig-essen"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727 active-trail">
                      <a
                        href="/fakten-uber-durchfall/"
                        className="active-trail"
                      >
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-ursachen-behand"
                  className="block block-menu"
                >
                  <h2>Durchfall - Ursachen &amp; Behandlung</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/fakten-uber-durchfall/" title>
                          Fakten über Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/durchfallmythen/" title>
                          Tipps bei Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/ursachen-von-durchfall/" title>
                          Ursachen von Durchfall
                        </a>
                      </li>
                      <li className="expanded active-trail">
                        <a
                          href="/richtig-essen/"
                          title
                          className="active-trail active"
                        >
                          Gesunde Essgewohnheiten
                        </a>
                        <ul className="menu">
                          {/*<li className="first leaf">
                            <a href="/nahrungsmittelallergie/" title>
                              Nahrungsmittelallergien
                            </a>
                          </li>
                          <li className="leaf">
                            <a href="/lebensmittelvergiftung/" title>
                              Lebensmittelvergiftung
                            </a>
                          </li>*/}
                          <li className="last leaf">
                            <a href="/rezepte/" title>
                              Leckere Rezepte
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded">
                        <a href="/stress-durchfall/" title>
                          Stress und Durchfall
                        </a>
                        <ul className="menu">
                          <li className="first leaf">
                            <a href="/hohe-arbeitsbelastung/" title>
                              Hohe Arbeitsbelastung
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="/prufungssituationen/" title>
                              Prüfungssituationen
                            </a>
                          </li>
                        </ul>
                      </li>
                      {/*<li className="leaf">
                        <a href="/chronischer-durchfall/" title>
                          Chronischer Durchfall
                        </a>
                      </li>*/}
                      <li className="leaf">
                        <a href="/gastroenteritis/" title>
                          Magen-Darm-Grippe
                        </a>
                      </li>
                      {/*<li className="leaf">
                        <a href="/norovirus/" title>
                          Norovirus
                        </a>
                      </li>*/}
                      <li className="last leaf">
                        <a href="/durchfall-wahrend-ihrer-periode/" title>
                          Durchfall während Ihrer Periode
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="region region-sidebar">
                <div id="block-block-9" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/imodium-info-box-grafik_0.gif) no-repeat center top #eaf4f5",
                        padding: "50px 18px 20px 18px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4 style={{
                        fontWeight: "bold",
                        fontSize: "30px",
                        lineHeight: "26px",
                        margin: "20px 0 10px 0",
                        fontFamily: "'Open Sans', sans-serif",
                      }}>REZEPTE</h4>
                      <p>
                        <img
                          alt
                          src="/sites/default/files/teaser_rezepte.jpg"
                          style={{
                            width: "174px",
                            height: "106px"
                          }}
                        />
                        <br />
                      </p>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "15px 0",
                          "line-height": "18px"
                        }}
                      >
                        Eine ausgewogene Ernährung ist eine wichtige
                        Voraussetzung für Gesundheit und Wohlbefinden. Hier
                        finden Sie Rezepte, die lecker sind.
                        <br />
                        <div
                          style={{
                            margin: "10px 0 0 0"
                          }}
                        >
                          <a className="georgia arrow_01" href="/rezepte/">
                            Mehr erfahren
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">Ernährung </div>
                        </div>
                      </div>
                    </h1>
                    <div className="flash_container">
                      <div className="field field-name-field-teaser-stuff field-type-text-long field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            <p>
                              <img
                                alt
                                src="/sites/default/files/ernaehrung.jpg"
                                style={{
                                  width: "695px",
                                  height: "286px"
                                }}
                              />
                            </p>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div className="std-site-alternative">
                      <div div className="firstrow">
                        <div className="left">
                          <div className="quote">
                            <span style={{ top: "27px" }}>
                              <h2>
                                <div className="field field-name-field-center-title field-type-text-long field-label-hidden">
                                  <div className="field-items">
                                    <div className="field-item even">
                                     Essverhalten zur Vorbeugung von Durchfall
                                    </div>
                                  </div>
                                </div>{" "}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <p className="subline">
                            Vielen Menschen erscheint ein gut funktionierendes
                            Verdauungssystem als selbstverständlich - jedoch nur
                            bis zum Auftreten von Magen- und Darm-Problemen. Sie
                            können die Verdauung nicht nur
                            durch das, was Sie essen, sondern auch wie Sie essen
                            unterstützen.{" "}
                          </p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="secondrow">
                        <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div
                              className="field-item even"
                              property="content:encoded"
                            >
                              <h3>
                                Hier einige Tipps um Durchfall vorbeugen zu können.
                              </h3>
                              <ul>
                                <li>
                                  Erst nach 20 Minuten signalisiert Ihr Magen
                                  Ihrem Gehirn, dass er genug hat. Somit essen
                                  Sie wahrscheinlich eine kleinere Portion, wenn
                                  Sie langsam essen – zugleich ist Ihr Körper
                                  weniger mit der Verdauung belastet.
                                </li>
                                <li>
                                  Wenn Sie hingegen Ihr Essen hastig
                                  verschlingen, gelangt dabei zusätzliche Luft
                                  in den Magen, was Blähungen und eine schlechte
                                  Verdauung hervorrufen kann.
                                </li>
                                <li>
                                  Kleinere, leichtere Mahlzeiten sind leichter
                                  verdaulich als grosse und schwere. Achten Sie
                                  daher darauf, wenig und dafür öfter am Tag zu
                                  essen. Snacks können Ihnen helfen, den
                                  kleinen Hunger zwischendurch zu stillen.
                                </li>
                                <li>
                                  Da Ihr Verdauungssystem zur Schlafenszeit am
                                  langsamsten arbeitet, sollten Sie Ihre letzte
                                  Mahlzeit des Tages spätestens 3 Stunden vor
                                  dem Schlafen einnehmen, um besser schlafen zu
                                  können. Sollten Sie sich vor dem Schlafen
                                  gehen trotzdem hungrig fühlen, greifen Sie z.B. zu
                                  einer Banane.
                                </li>
                                <li>
                                  Vermeiden Sie schwere oder stark gewürzte
                                  Speisen und trinken Sie Alkohol nur in Massen.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>{" "}
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
